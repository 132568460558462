import React from "react"
import Layout from '../components/Layout'
import SEO from "../components/SEO"

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
}

const Start = () => {
    return (
        <Layout>
        <SEO title={"Start"} />
            <div className="rbs-section">
                <div className="rbs-box-a">
                    <div className="content">
                        <h1 className="title-xl mb-8">
                            Start
                        </h1>
                        <p className="body-l mb-8 md:w-3/4">
                            Get started with a free 1hr brand strategy workshop. We will discuss positioning, values and business strategy. After the workshop you'll recieve a case study of the findings and will be ready to start with the Remote Brand Sprint. 
                        </p>

                        <div className="grid grid-cols-3 gap-8 mb-8">
                            <div>
                                <p className="title-s-b">Participants</p>
                                <p className="body-l mb-4 md:w-3/4">
                                    1–3 members
                                </p>
                                <p className="body text-rbs-black-50">
                                    (founders, executive team or whoever has the agency for strategic business decision)
                                </p>
                            </div>
                            <div>
                                <p className="title-s-b">Scope</p>
                                <p className="body-l mb-4 md:w-3/4">
                                    1hr video call
                                </p>
                                <p className="body text-rbs-black-50">
                                    You will recieve the instructions via email. No preparation needed.
                                </p>
                            </div>
                            <div>
                                <p className="title-s-b">Outcome</p>
                                <p className="body-l mb-4 md:w-3/4">
                                    Research document of the findings
                                </p>
                                <p className="body text-rbs-black-50">
                                    This will be the starting point for your Remote Brand Workshop. However, if you decide not to continue with us, you are free to use the findings on your own or use them as a brief working with another brand design studio.
                                </p>
                            </div>
                        </div>
                        <a href="https://calendly.com/remotebrandsprint/brand-strategy-workshop" className="body-l link-arrow">Book Your Free Workshop</a>
                    </div>
                </div>
                <div className="rbs-bg bg-07-a"></div>
            </div>

        </Layout>
    )
}

export default Start